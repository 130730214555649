import { cn } from '../../utils/cn'

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

export function Container({ className, ...props }: ContainerProps) {
  return (
    <div
      style={props.style}
      className={cn(
        'container-padding mx-auto w-full max-w-screen-xl px-6 lg:px-20 xl:px-24',
        className
      )}
      {...props}
    >
      {props.children}
    </div>
  )
}
