import ErrorPage from '@cerifi/ui/components/error-page'

const ErrorMessage = ({
  reset,
  error,
}: {
  reset: () => void
  error: Error
}) => {
  return (
    <ErrorPage
      title="500 Server Error"
      message="Sorry, unexpected error. The server encountered an error and could not complete your request."
    />
  )
}

export default ErrorMessage
