import { ButtonLink } from '../button'
import { Container } from '../container'
import { Divider } from '../divider'

export default function ErrorPage({
  title,
  message,
}: {
  title: React.ReactNode
  message: React.ReactNode
}) {
  return (
    <div className="bg-backgroundTint min-h-[100vh] pt-[7rem]">
      <Container>
        <div className="flex flex-col items-center">
          <h1 className="text-[2rem] sm:text-[3.75rem]">{title}</h1>

          <Divider className="mb-6 mt-1 w-full max-w-[19rem]" />

          <div className="max-w-[35rem] text-center text-base">{message}</div>

          <ButtonLink href="/" className="mt-6">
            Go Home
          </ButtonLink>
        </div>
      </Container>
    </div>
  )
}
